.features-wrap {
    width: 100%;
    background-color: $mild;
    padding: 0vw 6vw 0vh 6%;
    left: calc(-50vw + 50%); /*Centres div*/
    margin: 50px 0px;

    @include media-desktop() {
      width: 1000px;
      margin: 75px 0px 20px 0px;
      padding: 0px 75px 0px 75px;
    }
  }

.features {
    margin: 30px 0px 50px 0px;
    width: 100%;
  }

.card-cols {
    display: grid;
    column-gap: $gutterwidth * 0.25;
    row-gap: 0px;
    grid-template-columns: 1fr 7fr ;
}

@mixin col() {
  display: grid;
  column-gap: $gutterwidth * 2;
  row-gap: $gutterwidth;
}

.feats-cols {
  @include media-desktop() {
    display: grid;
    column-gap: $gutterwidth * 2;
    row-gap: 0px;
    grid-template-columns: 2fr 3fr;
  }
}

.feats-text-wrap {
    margin-top: -18px;
    padding-bottom: 30px;

    @include media-desktop() {
      margin: auto 0;
      padding-bottom: 20px;
    }
  }
  .feature-text {
    padding: 6px 0px 18px 20px;
    @include media-desktop() {
      padding: 12px 0;
    }
    p {
      font-size: $font-size-regular;
      line-height: $line-height-big;
      border-radius: $radius-4;
      background-color: #cde8f4;
      display: inline;
      padding: 6px 12px;
      margin: 0px;
      /* Needs prefixing */
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
  }

  .mixup-container{
    background-color: #fff;
    margin: 0px 0px 30px 0px;
  }

  .add-pointer {
    cursor: pointer;
  }

  .no-pointer {
    cursor: alias;
  }

  .features-container {
    padding: 50px 0px 30px 0px;
  }

  .mixup-wrap {
    @include media-desktop() {
      padding-top: 30px;
    }
    @include media-tablet() {
      padding-bottom: 20px;
    }
    @include media-mobile() {
      padding-bottom: 20px;
    }
  }

  .mixup-container {
    padding: 20px;
  }

  .mixup-container {
    border-radius: $radius-8;
    transition: 0.3s;
  }

  .feats-card {
    padding: 0px 0px 20px 20px;
    @include media-desktop() {
      padding: 0px 0px 20px 0px;
    }
  }

  .mixup-card, .feats-card {

    column-gap: 10px;

    .card-avatar {
      width: 80px;
      height: auto;
      border-radius: $radius-4;
    }

    .card-name {
      padding-bottom: 0px;
      margin: 2px 0px 0px 10px;
      bottom: 0%;
      .card-title {
        color: $chill;
        font-weight: 500;
        font-style: italic;
        font-size: $font-size-big;
        line-height: $line-height-big;
        margin: 0px;
      }
      .card-sciename {
        color: $grey;
        font-style: italic;
        font-weight: 400;
        font-size: $font-size-regular;
        line-height: $line-height-regular;
        margin: 0px 0px 3px 0px;
      }
      .card-gername {
        line-height: $line-height-regular;
        font-size: $font-size-regular;
        text-align: left;
        margin: 0px;
      }
    }
  }
.card-text {
  p {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }
  p:first-child {
    padding-top: 20px;
  }
}

.danger-status__red,
  .danger-status__orange,
  .danger-status__gray,
  .danger-status__green {
    border: none;
    text-shadow: none;
}

.danger-status__red {
  color: $pain;
}
.danger-status__orange {
  color: $stress;
}
.danger-status__gray {
  color: $fun;
}
.danger-status__green {
  color: $peace;
}

.hover-shadow:hover {
  box-shadow: $shadow-hover;
}

.static-shadow, .hover-shadow {
  box-shadow: $shadow;
}

.audio-wrapper {
  padding-top: 10px;
  .audio {
    border-radius: $radius-8;
    padding: $padding-16;
  }
  @include media-desktop() {
    padding-top: 20px;
  }
}
