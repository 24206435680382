.global-buttons_container {
  float: right;
  display: flex;

  .global-button {
    padding: $padding-8 $padding-8 $padding-8 0;
    cursor: pointer;
  }
  .global-button:hover {
    svg {
      fill: $happy;
      stroke: $happy;
    }
  }
}
