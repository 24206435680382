.text {
  a {
    @extend %link;
    color: $relax;
    text-decoration: underline;
  }

  ul {
    list-style: none;
    li {
      position: relative;
      padding-left: 24px;

      &:before {
        content: "";
        position: absolute;
        left: 10px;
        top: calc($line-height-regular / 2);
        transform: translateY(-2px);
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: $chill;

        .dark-background & {
          background-color: $happy;
        }
      }
    }
  }
}
