.grid-container {
    margin: 0 auto;
    max-width: $max-width;
    @include media-desktop() {
      padding: 0 $container-padding;
    }
  }
  
  .masonry-root {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    width: 100%;
  
  
    .masonry-cell {
      display: flex;
      -webkit-flex-direction: column; 
      flex-direction: column;
      .masonry-item {
        padding: 16px;
        margin: 8px 0 0 0;
        justify-content: center;
        @include media-mobile() {
          padding: 1.75vw;
        }
        img {
          height: auto;
          border-radius: 4px;
        }
        .masonry-item__info {
          display: flex;
          flex-flow: row nowrap;
          margin: 0;
          .masonry-item__icon {
            width: 24px;
            height: 24px;
            margin: 24px 10px 0 0;
            svg {
              fill: $white;
              stroke: $white;
            }
            @include media-mobile() {
              display: none;
            }
          }
          .masonry-item__text {
            overflow-wrap: break-word;
            @include media-mobile() {
              margin-left: 6px;
            }

            h3 {
              margin: 8px 0 0 0;
              color: $white;
              font-size: $font-size-big;
              @include media-mobile() {
                margin: 4px 0 0 0px;
                font-size: $font-size-regular;
              }
            }
            p {
              margin: 0px;
              color: $grey;
              font-style: italic;
              font-size: $font-size-regular;
              line-height: $line-height-super-small;
              @include media-mobile() {
                font-size: $font-size-small;
                line-height: $line-height-ultra-small;
              }
            }
          }
        }
        .masonry-item__info:hover {
          .masonry-item__icon {
            svg {
              fill: $happy;
              stroke: $happy;
            }
          }
          .masonry-item__text {
            h3 {
              color: $happy;
            }
            p {
              color: $happy;
            }
          }

        }
      }
    }
  }
  