.auth {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.signup-form, .signin-form, .forgotpassword-form, .resetpassword {
    min-width: 150px;
    max-width: 400px;
    width: 100%;
    margin: auto;

    mat-form-field {
        width: 100%;
        margin-bottom: $vertical-rhythm;
    }

    button {
        margin-bottom: $vertical-rhythm;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
  }

.auth__links {
    font-size: $font-size-small;
    color: $grey; 
    > span {
        cursor: pointer;
    }
    > a {
        float: right;
    }
}

   
