/* Colors */
$white: #fff;
$footer-white: #fbfbfb;
$light: #e9eaeb;
$light-grey: #d1d1d1;
$grey: #a9a9a9;
$dark: #414d54;
$black: #27313c;

$peace: #98bc00;
$happy: #ffcf4a;
$fun: #ffb546;
$dark-fun: #dc9a35;
$stress: #ff7600;
$pain: #cb4f4f;

$mild: #edf4f8;
$chill: #3bb4e7;
$relax: #2f99c6;
$sleep: #12466a;

$text-color: $sleep;

/* Typography */

$default-font: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$hairline-font: "Lato Hairline", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-size-ultra-small: 10px;
$line-height-ultra-small: 13px;

$font-size-super-small: 12px;
$line-height-super-small: 16px;

$font-size-small: 14px;
$line-height-small: 20px;

$font-size-regular: 16px;
$line-height-regular: 24px;

$font-size-big: 19px;
$line-height-big: 27px;

$font-size-h1: 36px;
$line-height-h1: 40px;

$font-size-h2: 30px;
$line-height-h2: 36px;

$font-size-h3: 25px;
$line-height-h3: 30px;

$font-size-h4: 22px;
$line-height-h4: 28px;

$font-size-h5: 19px;
$line-height-h5: 27px;

$font-size-h6: 16px;
$line-height-h6: 24px;

/* Border Radius */

$radius-4: 4px;
$radius-8: 8px;
$radius-16: 16px;

$gutterwidth: 32px;

$vertical-rhythm: 8px;

$max-width: 1400px;
$text-max-width: 768px;
$card-max-width: 980px;

$container-padding: $gutterwidth;

$table-media-width: 100px;
$table-media-height: 60px;

/* Shadows */

$shadow:  0 10px 15px -3px rgba(0,0,0,.1),
          0 4px 6px -2px rgba(0,0,0,.05),
          0 -2px 8px 0 rgba(79,79,79,.05);

$shadow-hover:  0 12px 18px -3px rgba(0,0,0,.25),
                0 4px 6px -2px rgba(0,0,0,.07),
                0 -2px 10px 0 rgba(81,81,81,.1);

$font-outline: 1px 1px $white,
                1px -1px $white,
                -1px 1px $white;

/* Paddings */

$padding-8: 8px;
$padding-12: 12px;
$padding-16: 16px;
$padding-32: 32px;
$padding-64: 64px;
$header-top-padding: 96px;


:root {
  --font-size-jumbo: 40px;
  --line-height-jumbo: 45px;

  @include media-tablet-desktop {
    --font-size-jumbo: 60px;
    --line-height-jumbo: 60px;
  }
}
