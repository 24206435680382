.fieldbook-actions {
  display: grid;
  grid-template-columns: auto 1fr;

  // 12px are need to align with first column in table
  padding-left: calc(#{$padding-32} - 12px);
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-mdc-checkbox  .mat-checkbox-frame {
    border: 1px solid $light;
  }

  .mat-mdc-checkbox .obs-multi-select {
    padding-top: -5px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-ripple .mat-ripple-element,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color:rgba(0, 0, 0,  0.0);
  }
}

.fieldbook-actions, .fieldbook-table-checker {

  .mat-mdc-checkbox .mat-checkbox-frame,
  .mat-checkbox .mat-checkbox-background,
  .mat-checkbox-checked .mat-checkbox-background {
    transform: scale(1.6);
  }
}

.fieldbook {
    .fieldbook-table-checker {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox  .mat-checkbox-frame {
            border: 1px solid $relax;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-ripple .mat-ripple-element,
        .mat-checkbox-checked.mat-accent .mat-checkbox-background,
        .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
            background-color: $relax;
        }
    }

    .fieldbook__column-center {
      text-align: center;
    }

}

.vertical-rhythm {
  margin-bottom: $vertical-rhythm;
}

.fieldbook--audio {
  .audio {
    border-radius: $radius-8;
    padding: $padding-16;
  }
}

.fieldbook-row-link {
  cursor: pointer;
}



.fieldbook-details__container {
  display: flex;

  > .mat-icon {
    height: 60px;
    width: 60px;
    svg {
      fill: $black;
      stroke: $black;
    }
  }

  .fieldbook-details {
    margin-left: $container-padding;
    display: flex;
    flex-direction: column;
    
    .fieldbook-details__title {
      margin-bottom: $padding-32;
      width: 100%;
      h2 {
        margin-bottom: 0;
      }
      .mat-icon {
        height: $font-size-regular;
        width: $font-size-regular;
        svg {
          fill: $chill;
          stroke: $chill;
        }
      }
    }
  
    .fieldbook-details__subtitle {
      @extend .big;
    }
  
    .fieldbook-details__map {
      max-width: 600px;
    }

    .fieldbook-details__notes {
      margin-bottom: 32px;
    }

    .fieldbook-details__img img {
      border-radius: $radius-8;
      max-width: 448px;
    }
  }
}