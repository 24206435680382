@font-face {
  font-family: "Lato";
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
    url("/assets/fonts/subset-Lato-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "Lato";
  src: local("Lato Bold"), local("Lato-Bold"),
    url/("assets/fonts/subset-Lato-Bold.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Lato";
  src: local("Lato-Light"),
    url("/assets/fonts/subset-Lato-Light.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Lato Hairline";
  src: local("Lato-HairlineItalic"),
    url("/assets/fonts/subset-Lato-HairlineItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-HairlineItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "Lato Hairline";
  src: local("Lato-Hairline"),
    url("/assets/fonts/subset-Lato-Hairline.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-Hairline.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Lato";
  src: local("Lato-LightItalic"),
    url("/assets/fonts/subset-Lato-LightItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "Lato";
  src: local("Lato Black Italic"), local("Lato-BlackItalic"),
    url("/assets/fonts/subset-Lato-BlackItalic.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "Lato";
  src: local("Lato Black"), local("Lato-Black"),
    url("/assets/fonts/subset-Lato-Black.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Lato";
  src: local("Lato Italic"), local("Lato-Italic"),
    url("/assets/fonts/subset-Lato-Italic.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "Lato";
  src: local("Lato Regular"), local("Lato-Regular"),
    url("/assets/fonts/subset-Lato-Regular.woff2") format("woff2"),
    url("/assets/fonts/subset-Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
