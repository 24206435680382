$icon-border: 2px;

.icon-button {
  color: $chill;
  height: $font-size-regular * 2;
  font-size: $font-size-regular;
  line-height: $font-size-regular;
  border-radius: #{$font-size-regular} / 50%;
  padding: (calc($font-size-regular / 2) - $icon-border) ((calc($font-size-regular / 2) - $icon-border) * 2);
  border: $icon-border solid;
  font-weight: bold;
}
