.filter-manager {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas:
  ". i0 i0 i0 i0 i1 i1 i1 i1 s1 s1 .";
  @include media-mobile() {
    padding: $padding-32 0;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "i1"
      "i0";
    grid-row-gap: 0px;
    grid-column-gap: $padding-32;
  }
}

.name-query-filter, .tags-filter {
  margin: 0px 2vw 0px 2vw;
}
.name-query-filter {
  @include media-mobile() {
    margin: 45px 5vw;
  }
}
.tags-filter {
  @include media-mobile() {
    margin: 0px 5vw 40px 5vw;
  }
}

.name-query-filter {
  grid-area: i0;
  position: relative;
  .species-filter__container {
    position: absolute; 
    bottom: 0;
  }
}
.species-filter__container {
  display: flex;
  align-items: center;

  mat-icon {
    margin-top: auto;
    margin-bottom: 5px;
  }

  .species-filter__search {
      margin-right: $padding-16;
  }
}

.tags-filter {
  grid-area: i1;
}

.species-view-selector {
  grid-area: s1;
  display: flex;
  flex-direction: row;

  @include media-mobile() {
    display: none;
  }

  .view-selection-icon {
    width: $font-size-h3;
    height: $font-size-h3;
    margin: $vertical-rhythm;
    cursor: pointer;
    margin-top: auto;
  }
  .icon-bright {
    opacity: 1;
  }
  .icon-dim {
    opacity: 0.25;
  }

}

.species-control {
  max-width: $max-width;
  margin: 0 auto;
  padding: 100px $container-padding 0 $container-padding;
  @include media-mobile() {
    padding: 75px $container-padding 0 $container-padding;
  }
  .filter-text {
    color: #fff;
    text-align: center;
    margin: 0 20vw 20px 20vw;
    @include media-tablet() {
      margin: 0 10vw 20px 10vw;
    }
    @include media-mobile() {
      margin: 0px 20px 0px 0px;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
.mat-mdc-autocomplete-panel.tags-overlay:after,
.mat-mdc-autocomplete-panel.query-overlay::after {
  content: "";
  position: absolute;
  width: 50px; // important to place the arrow in relation to width
  right: 50%;
  height: 11px;
  top: -10px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 10.918a7.5 7.5 0 005.848-2.805L22.612.935a2.5 2.5 0 013.952.069l5.156 6.902a7.5 7.5 0 006.008 3.012' fill='%23FFF'/%3E%3C/svg%3E");
}
.species-filter__container {
  width: 100%;

  .species-form {
    flex-grow: 4;
    
    mat-form-field {
      width: 100%;

      .mat-mdc-text-field-wrapper:hover>.mdc-line-ripple::before {
          border-color: rgba($color: #fff, $alpha: 0.0);
        }

      .mat-mdc-text-field-wrapper {
        background-color: rgba($color: #fff, $alpha: 0.0);
    
        .mat-mdc-form-field-flex>.mat-mdc-form-field-infix {
          min-height: 0px;
          padding: 6px 0px;

          input {
            font-weight: 400;
            font-size: $font-size-big;
            color: #fff;
          }
          // only this seems to work on the tags-input-placeholder-text
          input::-webkit-input-placeholder{
            color: #fff;
          }
          input::-moz-placeholder {
            color: #fff;
          }
        }

        .mdc-line-ripple::before {
          height: 3px;
          background-color: rgba($color: #fff, $alpha: 0.3);
        }

        .mdc-line-ripple::after {
          height: 3px;
          border-bottom-color: #e9e9e9;
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        height: 0px;
      }

    }
  }

}
