.audio {
  background-color: $relax;
  box-shadow: $shadow;
  overflow: hidden;

  display: grid;

  .audio__spectrogram_container {
    position: relative;
    line-height: 0px;
    img {
      opacity: 0.75;
      height: 100%;
    }
    .audio__spectrogram_container__cover {
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      height: 100%;
      img {
        height: 100%;
        width: auto;
        opacity: 1;
      }
    }
  }

  .audio__info {
    display: flex;
    height: 100%;
    p {
      margin: 0;
    }
    .audio__info__play-button {
      vertical-align: top;
      padding: 0 $padding-8 $padding-8 0;
      display: table-cell;
      cursor: pointer;
      mat-icon {
        height: $line-height-regular * 1.75;
        width: $line-height-regular * 1.75;
      }
    }
    .audio__info__text {
      padding: 0 $padding-8 $padding-8 0;
      .audio__info__text__title {
        font-size: $font-size-regular;
        line-height: $line-height-regular;
        font-weight: bold;
      }
      .audio__info__text__copyright {
        font-size: $font-size-super-small;
        line-height: $line-height-super-small;
        opacity: 0.5;
      }
    }
  }
  .audio__time {
    display: flex;
    font-size: $font-size-super-small;
    line-height: $line-height-small;
    font-weight: bold;

    .audio__time__start {
      text-align: left;
      margin-right: auto;
    }
    .audio__time__end {
      text-align: right;
      margin-left: auto;
      opacity: 0.5;
    }
  }
}
.audio--no-text {
  .audio__time {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  grid-template-columns: $line-height-regular * 1.75 + 2 * $padding-8 1fr;
}
