.card {
  border-radius: $radius-8;
  box-shadow: $shadow;
  background-color: $white;
  overflow: hidden;
  height: 100%;

  .card__inner {
    padding: $padding-32;
  }

  .card__sub-headline {
    color: $grey;
    margin-bottom: calc($vertical-rhythm * 2);
  }

  .card__headline {
    font-size: $font-size-h2;
    line-height: $line-height-h2;
    font-weight: bold;
    margin-bottom: $vertical-rhythm;
  }

  .card__body:not(:last-child) {
    margin-bottom: 2 * $vertical-rhythm;
  }

  .card__cta {
    &:not(:last-child) {
      margin-bottom: calc($vertical-rhythm * 3);
    }
  }

  .card__cta--goto {
    display: block;
    font-size: $font-size-regular;
    line-height: $line-height-regular;
    font-style: italic;

    a {
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    }
  }

  &.card--blue {
    background-color: $sleep;
    text-align: center;

    .card__sub-headline {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.card--transparent {
    border-radius: 0;
    box-shadow: none;
    overflow: visible;
    background-color: transparent;

    .card__picture {
      img {
        border-radius: $radius-8;
        box-shadow: $shadow;
        margin-bottom: $padding-32;
      }
    }

    .card__inner {
      padding: 0;
    }
  }

  &.card--jumbo {
    position: relative;
    font-size: 0;
    line-height: 0;
    width: auto !important;

    &.card--jumbo--vertical {
      padding-bottom: 177.77%;
    }

    &.card--jumbo--horizontal {
      padding-bottom: 56.25%;
    }

    .card__picture {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .card__inner {
      position: absolute;
      left: 0;
      bottom: 0;

      font-size: $font-size-regular;
      line-height: $line-height-regular;
    }

    .card__sub-headline {
      display: inline-block;
      border-radius: $radius-4;
      padding: 4px 8px;
      background-color: $white;
      font-size: $font-size-h3;
      line-height: $line-height-h3;
      font-weight: bold;
      color: $text-color;
    }

    .card__headline {
      margin-bottom: 4 * $vertical-rhythm;
      font-size: $font-size-h1;
      line-height: $line-height-h1;
    }

    .card__body {
      font-size: $font-size-big;
      line-height: $line-height-big;
      font-style: italic;
    }
  }
}
