.youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  img.yt-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 10%;
  }
}
.youtube-container--no-consent {
  cursor: pointer;
}
.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
