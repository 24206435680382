.select-wrapper {

    position: relative;

    .button--select {
        padding-top: $padding-8;
    }

    .select-options {
        position: absolute;
        left: -50px;
        list-style-type: none;
        margin-top: 11px;
        padding: $padding-8 0px;
        box-shadow: $shadow;
        border-radius: $radius-8;
        background-color: $white;
    }

    .select-options > li {
        min-width: 120px;
        padding: $padding-8 $padding-16;
        cursor: pointer;
        color: $black;
    }

    .select-options > li:hover {
        background-color: $light-grey;
    }

    .select-options:before {
        content: "";
        position: absolute;
        left: 30%;
        width: 50px;
        height: 11px;
        top: -11px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='50' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 10.918a7.5 7.5 0 005.848-2.805L22.612.935a2.5 2.5 0 013.952.069l5.156 6.902a7.5 7.5 0 006.008 3.012' fill='%23FFF'/%3E%3C/svg%3E");
    }
}
