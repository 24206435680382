.mat-mdc-dialog-container {
    border-radius: $radius-8 !important;
    padding: 35px !important;

    .mat-mdc-dialog-title {
        font-weight: 800;
    }

    .close.mat-mdc-button {
        float: right;
        padding: 0px 13px;
        margin: -10px -20px 0px 0px;
        width: 30px;
    }

    .mat-mdc-dialog-actions {
        float: right;
        padding: 15px 0px 20px 0px;
        .mat-mdc-button-base {
            margin: 0px -16px 0px 30px !important;
            font-weight: 400;
            font-size: $font-size-regular;
        }

        .yes-or-no-dialog_ok {
            color: $relax;
        }
    }
}