#portrait-card, #portrait-calc {
  margin: 0px auto;
  background-color: $white;
  border-radius: $radius-8;
  box-shadow: $shadow;
  display: block;
  @include media-desktop() {
    width: 1000px;
  }
}

.photo-text {
  @include media-desktop() {
    padding: 75px 75px 0px 75px;
  }
  @include media-tablet() {
    padding: 50px 50px 0px 50px;
  }
  @include media-mobile() {
    padding: 25px 25px 0px 25px;
  }
}

.img-wrapper__left {
  order: 0;
}

.img-wrapper__right {
  order: 0;
  @include media-desktop() {
    order: 1;
  }
}


.portrait-text {
  position: relative;
  h3, h4, h5 {
      margin: 0px 0px 15px 0px;
  }
  p {
    font-size: $font-size-regular;
  }
}

.funfacts-text {
  padding-left: 20px;
  border-left: 3px $chill solid;
  align-self: start;
}

.funfacts-text:not(:last-child) {
    margin-bottom: $line-height-regular;
}

.status-allgood {
  color: $peace;
}

.status-danger {
  color: $stress;
}

  .headline {
    margin: 0px 0px 15px 0px;
  }

  .text-left, .text-right {
      position: relative;
      text-align: right;
      float: right;
  }
  .text-left {
    @include media-desktop() {
      text-align: left;
      float: left;
    }
  }

  .photo-subtitles {
    font-size: $font-size-super-small;
    line-height: $line-height-super-small;
    margin: 0px;
    padding-top: 10px;
    
    a {
      color: $grey;
      text-decoration: none;
      transition: none;
      opacity: 1;
      margin-right: 5px;
    }
    .photo-desc {
        color: $black;
        font-size: $font-size-small;
        line-height: $line-height-small;
        font-weight: 500;
        margin-left: 5px;
    }
  }

  .photo-4-3, .photo-3-4 {
    img {
      border-radius: $radius-4;
      box-shadow: $shadow;
    }
  }

  .photo-4-3 {
    img {
      aspect-ratio: calc(4 / 3);
      object-fit: cover;
    }
  }
  .photo-3-4 {
    img {
      aspect-ratio: 3 / 4;
      object-fit: cover;
    }
  }

  .funfacts-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0px;
  }
