.sources {
  background-color: $mild;
  border-bottom-left-radius: $radius-8;
  border-bottom-right-radius: $radius-8;
  margin-top: 50px;

  @include media-desktop() {
    padding: 25px 75px 75px 75px;
    width: 1000px;
  }
  @include media-tablet() {
    padding: 25px 50px 75px 50px;
  }
  @include media-mobile() {
    padding: 25px 25px 50px 25px;
  }

  .sources__list {
    p {
      word-break: break-word;
    }
    @include media-tablet-desktop() {
      -webkit-columns: var(--num-cols);
      -moz-columns: var(--num-cols);
      columns: var(--num-cols);
      -moz-column-fill: balance;
      column-fill: balance;
      p {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }
  }

  .sources__selected {
    background-color: $white;
    padding: 0px 5px;
    margin-left: -5px;
    border-radius: $radius-4;
  }

}
