.frame {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .frame__main {
    flex-grow: 1;
  }

  .frame__main--menu-open {
    background-color: rgba(0, 0, 0, 0.1);
  }


}
