.header {
  text-align: center;
  position: relative;
  
  .header__top-container {
    padding: $container-padding;
    background: url("/assets/imgs/background_grain_opt.png");
  }

  .header__top {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: $max-width;
    margin: 0 auto;
    @include media-mobile {
      margin: 0px;
    }
  }

  .header__title {
    width: 60%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .header__breadcrumbs {

    .button--home {
      @include media-mobile {
        border: none;
      }
    }

    .button--home__logo {
      display: flex;
      align-items: center;
      h5 {
        margin: 0px 0px 0px 8px;
        line-height: $line-height-super-small;
      }
      img {
        height: $font-size-big;
        width: auto;
      }

    }

    button {
      vertical-align: top;
    }

    button:first-child {
      float: left;
    }
    
    button:not(:first-child) {
      margin-left: 12px;
      @include media-mobile {
        visibility: hidden;
        margin-right: 6px;
      }
    }
  }

  &:not(.header--dark-page) {
    filter: drop-shadow($shadow);
    transform: translateZ(0);

  }

  .header__introduction {
    font-size: $font-size-big;
    line-height: $line-height-big;
  }

  .header__background-container {
    background: url("/assets/imgs/background_grain_opt.png");
    padding: 120px 15% 0;
    padding-top: $header-top-padding;
    padding-bottom: calc($vertical-rhythm * 8);
    
    @include media-mobile {
      padding-bottom: calc($vertical-rhythm * 4);
      padding-top: 0px;
    }

  }

  &.header--dark-page {
    .header__top-container {
      background: none;
    }

    .header__background-container {
      background: none;
    }
  }

  &.header--menu-open {
    .header__top-container {
      position: relative;
      background: none;
    }
  }

  .header__seperator {
    margin-top: calc($vertical-rhythm * 6);
    margin-bottom: calc($vertical-rhythm * 4);
  }

  &.header--with-image {
    .header__background-container {
      padding-bottom: 150px;

      @include media-tablet {
        padding-bottom: 240px;
      }

      @include media-desktop {
        padding-bottom: 320px;
      }
    }

    .header__image {
      max-width: $max-width;
      margin: -100px auto auto;
      padding: 0 $container-padding;
      img {
        box-shadow: $shadow;
        border-radius: $radius-8;
        position: relative;
        z-index: 1;

        @include media-tablet {
          margin-top: -180px;
        }

        @include media-desktop {
          margin-top: -240px;
        }
      }
    }
  }

  &:not(.header--with-image) {
    .header__image {
      display: none;
    }
  }

  .header__icon {
    width: 36px;
    height: 36px;
    svg {
      fill: $happy;
      stroke: $happy;
    }
    margin: 0px;
  }

  .header__sciename {
    color:  $happy;
  }

  .menu__eye {
    width: 30px;
  }
}
