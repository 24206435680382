.image-text__left {
    order: 1;
}

.image-text__right {
    order: 2;

    @include media-mobile() {
        order: 1;
    }
}
