app-tiny-audio {
  line-height: 0px;
}

.tiny-audio {
  display: inline-block;
  cursor: pointer;
  svg {
    fill: $chill;
    stroke: $chill;
  }
}
