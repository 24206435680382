div.mat-mdc-autocomplete-panel.query-overlay {
    position: relative; // needed to place absolute pseudo element
    overflow: initial !important; // the only dangerous part. needed to place pseudo element outside of the box
    padding: 15px;
    margin-top: 31px;
    min-width: 600px;
    border-radius: $radius-8 !important;
    left: -35px;

    @include media-tablet() {
        left: -150px;
        min-width: 60vw;
    }

    @include media-mobile() {
        left: -15vw;
        min-width: 80vw;
    }

    .mat-mdc-option>.mdc-list-item__primary-text {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }
}

.result-selection {
    height: 45px !important;
    padding: 0px !important;

    .result-name {
        padding: 0px 6px;
        font-size: 23px;
        color: black;
        font-weight: 900;

        @include media-mobile() {
            font-size: 20px;
        }
    }

    .result-lat-name {
        padding: 0px 6px;
        font-style: italic;
        font-size: 18px;
        font-weight: 700;
        color: #ffcf4a;

        @include media-mobile() {
            font-size: 16px;
        }
    }

    .result-synonym {
        padding: 0px 6px;
        font-style: italic;
        font-size: 18px;
        font-weight: 700;
        color: #A9A9A9;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;


        @include media-mobile() {
            font-size: 16px;
        }
    }
}

.more-results {

    span {
        margin-top: 10px 0px;
        font-weight: 600;
        width: 100%;
    
        .results-count {
            float: left;
            color: #A9A9A9;
        }
    
        .results-link {
            float: right;
            text-align: right;
            font-weight: 600;
            color: #3BB4E7;
        }
    }

    .mat-pseudo-checkbox-minimal {
        display: none;
    }
}

.results-divider {
    width: 100%;
    height: 5px;
    border-top: 3px solid #E9EAEB;
    padding-bottom: 10px;
}


mark {
    border-bottom: 2px solid currentColor;
    background-color: #fff;
    color: currentColor;
    display: inline-block;
    line-height: 1.0;
    white-space: pre;
    padding: 0px;
    text-shadow: $font-outline;
}