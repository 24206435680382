.mat-mdc-table {
  width: 100%;
  font-family: $default-font;

  td.mat-mdc-cell, th.mat-mdc-header-cell {
    padding-left: $padding-32;
  }

  td.mat-mdc-cell:last-of-type, th.mat-mdc-header-cell:last-of-type {
    padding-right: $padding-32;
  }

  .mat-mdc-cell {
    font-size: $font-size-regular;
    vertical-align: middle;
    color: $black;
    padding: $padding-12 0;

    img, audio {
      width: $table-media-width;
      border-radius: $radius-8;
    }

    img {
      height: $table-media-height;
      object-fit: cover;
    }
  }
  .mat-mdc-header-cell, .mat-sort-header, .mat-sort-header-content {
    font-size: $font-size-regular;
    text-align: left;
    vertical-align: middle;
    color: $black;
  }
  .mat-mdc-row {
    // Allow image and add 12px of bottom/top padding
    height: calc(#{$table-media-height} + #{$padding-12} * 2);
  }
  .mat-mdc-header-row {
    // Allow a two line header and add 8px of bottom/top padding
    height: calc(#{$font-size-regular} * 2 + #{$padding-8} * 2);
    background: $light;
  }
}
.mat-mdc-paginator {
  font-family: $default-font;
  font-size: $font-size-regular;
  color: $black;
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-mdc-paginator-page-size .mat-select-trigger {
    font-family: $default-font;
    font-size: $font-size-regular;
    color: $black;
  }
}
.mat-mdc-form-field-appearance-legacy .mat-form-field-label, .mat-form-field.mat-focused .mat-form-field-label {
  color: $white;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mat-form-field-ripple{
  background-color: $relax;
}
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: $relax;
}
