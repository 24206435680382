.home {
   background: url('/assets/imgs/background_grain.png');
}

.hero_image {
  width: 100%;
}

.home_header {
  position: absolute;
  top: 0;
  
  display: flex;
  flex-direction: column;
  margin-bottom: $container-padding * 2;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .home_header__titles {

    margin-bottom: calc(var(--font-size-jumbo) * 0.5);

    @include media-mobile-tablet() {
      p.subtitle {
        display: none;    
      }
    }

    @include media-mobile-tablet() {
      h1.jumbo {
        font-size: calc(var(--font-size-jumbo) * 0.75);
        line-height: calc(var(--line-height-jumbo) * 0.75);
        margin-top: $padding-8;
        margin-bottom: calc(var(--font-size-jumbo) * 0.5);
      }
    }

    @include media-mobile() {
      h1.jumbo {
        font-size: calc(var(--font-size-jumbo) * 0.5);
        line-height: calc(var(--line-height-jumbo) * 0.5);
        margin-top: $padding-8;
        margin-bottom: calc(var(--font-size-jumbo) * 0.25);
      }
    }
  }
}

.header {
  &.header--dark-page {
    .home_header {
      .header__top-container {
        background: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
      }
    }
  }
}
