.white-card {
  background-color: $white;
  border-radius: $radius-8;
  padding: $padding-32;
  display: grid;
  column-gap: $gutterwidth;
  row-gap: $gutterwidth;
  grid-template-columns: 7fr 1fr;

  @include media-mobile() {
    grid-template-columns: 1fr;
  }

  .white-card__text {
    color: $black;
    opacity: .6;
    font-size: $font-size-small;
  }

  .white-card__logo {
    justify-self: end;
  }
}
