div.mat-mdc-autocomplete-panel.tags-overlay {
  margin-top: 31px;
    margin-right: 20%;
    left: -230px;
    position: relative; // needed to place absolute pseudo element
    overflow: initial !important; // the only dangerous part. needed to place pseudo element outside of the box
    padding: 15px 10px;
    min-width: 850px;
    max-height: 55vh;
    border-radius: $radius-8 !important;
    
    @include media-tablet() {
      left: -200px;
      min-width: 60vw;
      max-height: none;
    }

    @include media-mobile() {
      left: -15vw;
      min-width: 70vw;
      max-height: none;
    }

  }

  .tag-name-input {
    margin-top: 15px;
  }

.tags-wrapper {
    justify-content: center;
    padding: 0;
    margin: 0;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: scroll !important;
    overflow-x: hidden !important;
    max-height: 50vh;
    @include media-mobile() {
        height: 100%;
    }
}

.tags-wrapper::-webkit-scrollbar {
  width: 8px;
}
.tags-wrapper {
  scrollbar-width: thin;
  scrollbar-color: $light-grey;
}

.tags-wrapper::-webkit-scrollbar-thumb {
  background-color: $light-grey;
  border-radius: 6px;
}

.tags-selection {
  padding: 5px !important;
}

.mat-mdc-chip, .tag-list-chip {
    border-radius: 5px !important;
    background:  #E9EAEB !important;
    font-size: medium;
}

.tag-list-chip {
    padding: 7px 8px;
    margin: 2px;
    max-height: 35px;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
}