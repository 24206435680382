.button {
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 4px 14px;
  border: 2px solid $relax;
  overflow: visible;

  border-radius: $radius-4;

  font: inherit;
  line-height: 1;
  text-decoration: none;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  cursor: default;

  transition: all 0.3s;

  /* default colors */

  background: $relax;
  color: $white;

  img {
    height: $font-size-regular;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
  }

  .dark-background & {
    img {
      -webkit-filter: invert(0%); /* safari 6.0 - 9.0 */
      filter: invert(0%);
    }
  }

  &:hover {
    background-color: #3bb4e7;
    border-color: #3bb4e7;
    cursor: pointer;
  }

  &:active {
    background-color: #00709f;
    border-color: #00709f;
  }

  &:disabled {
    opacity: 0.3;
  }

  &.button--primary {
    background-color: $fun;
    border-color: $fun;
    color: $sleep;

    &:hover {
      background-color: #ffbb00;
      border-color: #ffbb00;
    }

    &:active {
      background-color: #be972b;
      border-color: #be972b;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
  &.button--link,
  &.button--text {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    line-height: inherit;
  }

  &.button--link {
    text-decoration: underline;
    @extend %link;
  }

  &.button--text {
    color: inherit;
  }

  &.button--lined {
    background: none;
    color: inherit;
    border-color: rgba(0,0,0,0.1);

    &:active {
      border-color: $text-color;
    }

    &:hover {
      border-color: rgba(0,0,0,0.4);
    }

    .dark-background & {
      border-color: rgba(255, 255, 255, 0.1);

      &:hover {
        border-color: rgba(255,255,255,0.4);
      }

      &:active {
        border-color: $white;
      }
    }
  }

  &.button--big {
    font-size: $font-size-big;
    line-height: $font-size-big;
    padding: 17px 24px;
    img {
      height: $font-size-big;
    }
  }

  @include media-mobile {
    &.button--big {
      font-size: $font-size-small;
      line-height: $font-size-small;
      padding: 9px 12px;
      img {
        height: $font-size-small;
      }
    }
  }
  &.button--square {
    width: 31px;
    height: 31px;
    padding: 0;
    @include media-tablet-desktop {
      &.button--big {
        width: 60px;
        height: 60px;
      }
    }
  }
  &.button--select {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 6px 10px;
    height: 40px;
    font-size: $font-size-big;
    line-height: $font-size-big;
    img {
      padding-left: 10px;
      height: $font-size-big;
      width: auto;
    }
  }

  &.button--cta {
    background-color: $stress;
    border-color: $stress;
    padding: 6px 8px;

    .button--cta__icon {
      margin-right: 6px;
    }
  }



  &.button--menu {
    height: $line-height-h1;
    padding: 0 8px;
    cursor: pointer;
    div {
      display: flex;
      align-items: center;
      h5 {
        margin: 0px;
        font-weight: normal;

      }
      mat-icon {
        margin-left: 10px;
      }
    }
    @include media-mobile {
      div {
        h5 {
          display:none;
        }
        mat-icon {
          margin-left: 0px;
        }
      }
    }
  }
}
