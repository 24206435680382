.menu {
  padding: 8 * $vertical-rhythm $gutterwidth 9 * $vertical-rhythm;

  .menu__content {
    margin: 0 auto;
  }

  .menu__intro {
    display: none;

    @include media-desktop {
      display: block;
      float: bottom;
      max-width: $text-max-width;
      margin-bottom: $line-height-regular;
      opacity: 0.45;
    }
  }

}

.menu-items {
  .menu__link-list {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
    @include media-desktop {
      display: grid;
      grid-template-columns: 33% 1fr;
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }
  .menu-link {
    padding: 0;
    font-size: $font-size-h2;
    line-height: $line-height-h2;
    display: flex;
    font-weight: bold;
    &:not(:last-child) {
      margin-bottom: calc($vertical-rhythm * 1.5);
    }

    .menu-link__icon {
      // Constant to push icons slightly down so they align better
      // with text
      top: 3px;
      position: relative;
      margin-right: $padding-16;
      width: $font-size-h2;
      height: $font-size-h2;
      flex-shrink: 0;
    }
  }

  .menu-link:hover {
    color: $happy;
    .menu-link__icon {
      svg {
        fill: $happy;
        stroke: $happy;
      }
    }
  }
  
}

.menu-items .menu-items--secondary {
  .menu-link {
   font-weight: normal;
  }
}


