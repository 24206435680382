.icon-test {
    li {
        display: inline;
        padding: 5px;
    }
    svg {
        fill: $black;
        stroke: $black;
    }
}
