.four-o-four {
  background: $sleep;
  min-height: 100vh;
}

.four-o-four__title {
  margin-bottom: calc($vertical-rhythm * 4);
  text-align: center;
}

.four-o-four__intro {
  margin-bottom: calc($vertical-rhythm * 8);
  text-align: center;

  @include media-tablet-desktop {
    // This width is due to actual shown text
    // So if the text changes so should this adjusted
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
}

.four-o-four__image {
  margin-bottom: calc($vertical-rhythm * 8);

  @include media-tablet-desktop {
    display: block;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

.four-o-four__brick {
  margin-bottom: calc($vertical-rhythm * 6);
}

.four-o-four__links {
  margin-bottom: calc($vertical-rhythm * 6);

  & > *:first-child {
    margin-bottom: calc($vertical-rhythm * 4);
  }

  @include media-tablet-desktop {
    border: 2px solid rgba(151, 151, 151, 0.23);
    padding: $padding-32;
    border-radius: $radius-8;
  }

  @include media-desktop {
    display: grid;
    grid-template-columns: 60% 1fr;
    padding: $padding-64;

    & > *:first-child {
      justify-self: end;
      padding-right: 20%;
    }
  }
}

.four-o-four__links__title {
  font-weight: normal;
  font-style: italic;
  padding-left: 12px;
  margin-bottom: calc($vertical-rhythm * 2);
}
