.mini-audio {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $table-media-width;
  height: $table-media-height;
  position: relative;
  background-color: $relax;
  border-radius: $radius-16;

  svg {
    fill: $mild;
    stroke: $mild;
  }

  .spectogram {
    background-image: url('/assets/imgs/spectogram_100_20.png');
    width: $table-media-width;
    height: CALC($table-media-height / 3);
  }

  .icon {
    position: absolute;
  }
}
