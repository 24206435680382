$icon-column-size: 48px;

.observation {
  .observation__species {
    display: flex;
    .observation__species__hero {
      margin-bottom: auto;
      border-radius: $radius-8;
      @include media-tablet-desktop() {
        width: 160px;
      }
      @include media-mobile() {
        max-width: 160px;
      }
      height: auto;
    }
    .observation__species__info {
      padding-left: $padding-16;
      @include media-tablet-desktop() {
        padding-left: $padding-32;
      }
    }
    .observation__species__links {
      display: flex;
      align-items: flex-start;

      > :not(:first-child) {
        margin-left: $padding-16;
      }

      .icon-button {
        margin-top: $padding-8;
      }

      .mat-icon {
        margin-top: 8px;
        width: 32px;
        height: 32px;
      }
    }
  }
  .observation__evaluations {
    display: grid;
    grid-template-columns: $icon-column-size 1fr;
    grid-column-gap: $padding-32;
    > mat-icon {
      width: $icon-column-size;
      height: auto;
      svg {
        stroke: $black;
        fill: $black;
      }
    }
    > div {
      grid-column-start: 1;
      grid-column-end: 3;
      @include media-desktop() {
        grid-column-start: 2;
      }
    }
    .observation__evaluations__container {
      display: grid;
      @include media-desktop() {
        margin-right: $container-padding;
        grid-template-columns: repeat(2, 1fr);
      }
      grid-column-gap: $padding-32;
      grid-row-gap: $padding-32;
      > * {
        background-color: $white;
        border-radius: $radius-8;
        box-shadow: $shadow;
        padding: $padding-32;
        .avatar {
          border-radius: $radius-8;
          box-shadow: $shadow;
        }
      }
      .audio {
        border-radius: $radius-8;
        box-shadow: $shadow;
        padding: $padding-16;
      }
    }
    .observation__evaluations__card--only {
      grid-column-start: 1;
      grid-column-end: 3;
      @include media-desktop() {
        .map {
          width: 50%;
          padding-top: 10%;
          padding-bottom: 10%;
        }
        p, h1, h2, h3, h4, h5 {
          width: 75%
        }
      }
    }
    .evaluations__subtitle {
      padding-bottom: $padding-8;
    }
    .observation__evaluations__details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > mat-icon {
        min-width: $font-size-regular;
        width: $font-size-regular;
        margin-right: $padding-8;
        svg {
          fill: $chill;
          stroke: $chill;
        }
      }
      p {
        color: $chill;
      }
    }
    .observation__evaluations__suggested-species {
      margin-bottom: $line-height-regular;
      .observation__evaluations__suggested-species__row {
        display: flex;
        flex-direction: row;
        div:not(:first-child) > p {
          margin-left: $padding-8;
        }
        div:last-child {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .observation__faq {
    display: grid;
    grid-column-gap: $padding-32;

      @include media-tablet-desktop() {
        grid-template-columns: $icon-column-size 1fr;
      }
  }
}
