.container {
  max-width: $max-width;
  margin: 0 auto;
  padding: $container-padding 0;
  width: calc(100% - #{$container-padding} * 2);

  .container__text {
    max-width: $text-max-width;
    &.container__text--center {
      margin: 0 auto;
    }
  }

  @mixin column() {
    display: grid;
    column-gap: $gutterwidth;
    row-gap: $gutterwidth;
  }
  @mixin col() {
    display: grid;
    column-gap: $gutterwidth * 2;
    row-gap: $gutterwidth;
  }

  .columns-1 {
    @include column();
    @include media-tablet-desktop() {
      grid-template-columns: 1fr;
    }
  }
  .columns-2 {
    @include column();
    @include media-tablet-desktop() {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .columns-1-2 {
    @include column();
    @include media-tablet-desktop() {
      grid-template-columns: 1fr 2fr;
    }
  }

  .columns-3 {
    @include column();
    @include media-desktop() {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .columns-1-3 {
    @include column();
    @include media-desktop() {
      grid-template-columns: 1fr 3fr;
    }
  }

  .columns-3-1 {
    @include column();
    @include media-desktop() {
      grid-template-columns: 3fr 1fr;
    }
  }

  .columns-4 {
    @include column();
    @include media-tablet() {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-desktop() {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .columns-6 {
    @include column();
    @include media-tablet() {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-desktop() {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .columns-1-1 {
    @include column();
    @include media-desktop() {
      grid-template-columns: 1fr 1fr;
    }
  }
  .columns-2-1 {
    @include column();
    @include media-desktop() {
      grid-template-columns: 2fr 1fr;
    }
  }
  .columns-1-2 {
    @include column();
    @include media-desktop() {
      grid-template-columns: 1fr 2fr;
    }
  }

}

.no-top-padding {
  .container {
    padding-top: 0;
  }
}


.container--extra_top_padding {
  padding-top: $padding-64;
}

.container--card-width {
  max-width: $card-max-width;
}

.container--card {
  background-color: $white;
  border-radius: $radius-8;
  box-shadow: $shadow;
  margin-bottom: $padding-16;

  @include media-desktop() {
    margin-bottom: $padding-64;
  }

  @include media-tablet() {
    margin-bottom: $padding-32;
  }

  padding: 0;

  .container--card__section {
    padding: $container-padding;
  }

  .container--card__section--bottom {
    overflow: hidden;
    border-radius: 0 0 $radius-8 $radius-8;
  }

  .container--card__section--top {
    overflow: hidden;
    border-radius: $radius-8 $radius-8 0 0 ;
  }

  .container--card__section--mild {
    background-color: $mild;
  }

  .container--card__section--light {
    background-color: $light;
  }

  .container--card__section--no-top-padding {
    padding-top: 0px;
  }
}
