.language-selector {
  a {
    @extend %link;

    margin-left: 10px;
    &.active {
      opacity: 1;
    }
  }

}
