.popup-top {
  cursor: pointer;
  border-radius: $radius-16 $radius-16 0 0;
  padding: $padding-32;
  display: flex;

  .popup-top__hero {
    margin-bottom: auto;
    border-radius: $radius-8;
    width: 80px;
    height: auto;
  }
  .popup-top__info {
    p {
      margin: 0;
      font-size: $font-size-small;
      line-height: $line-height-small;
      color: $happy;
    }
    h2 {
      margin: 0;
    }

    .popup-top__info__synonym {
      color: $grey;
    }
    padding-left: $padding-32;
  }
  .popup-top__close {
    margin-left: auto;
    cursor: pointer;
    svg {
      fill: $chill;
      stroke: $chill;
    }
  }
}
.popup-bottom {
  background-color: $mild;
  display: flex;

  .popup-bottom__info {
    padding: $padding-32 0 $padding-32 $padding-32;
    display: flex;
    flex-direction: column;

    .popup-bottom__info__top {
      p {
        font-size: $font-size-small;
        line-height: $line-height-small;
        margin: 0;
      }
    }

    .popup-bottom__info__bottom {
      p {
        margin: 0;
        font-size: $font-size-super-small;
        line-height: $line-height-super-small;
        color: $grey;
        a {
          color: $grey;
        }
      }
    }
  }
  .popup-bottom__image {
    padding: $padding-32;

    img {
      width: 160px;
      height: auto;
      border-radius: $radius-8;
    }
  }

  .audio {
    padding: $padding-32;
  }
}

.popup-bottom--audio {
  background-color: $relax;
}

.popup-very-bottom {
  display: flex;
  padding-bottom: $padding-8;
}

.popup-very-bottom--link {
  margin-left: auto;
  margin-right: $padding-8;
  cursor: pointer;
  color: $chill;
  svg {
    fill: $chill;
    stroke: $chill;
    padding-top: $padding-12;
  }
}
