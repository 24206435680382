.abbr {
  position: relative;
  display: inline;

  .abbr__body {
    border-bottom: 1px dashed $grey;
  }

  .abbr__tooltip {
    display: block;
    position: absolute;
    top: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: $radius-4;
    padding: 4px 12px;
    background-color: $relax;
    opacity: 0;
    color: $white;
    font-size: $font-size-super-small;
    line-height: $line-height-super-small;
    white-space: nowrap;
    transition: opacity 0.3s;

    &.abbr__tooltip--fade-in {
      opacity: 0.9;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -6px;
      left: calc(50% - 9px);
      width: 18px;
      height: 18px;
      background-color: $relax;
      border-radius: $radius-4;
      transform: rotate(45deg);
    }

    &[aria-hidden="true"] {
      display: none;
    }

    .abbr__tooltip__inner {
      position: relative;
      z-index: 10;
    }
  }
}
