.map {
  box-sizing: border-box;
  position: relative;
  padding: 25%;
  @media (orientation: portrait) {
    padding: 50%;
  }
  border-radius: $radius-8;
  box-shadow: $shadow;
  width: 100%;
}

.hexbin-hexagon {
  stroke: #000;
  stroke-width: .5px;
}
.map-popup-container {
  width: 540px;
}
.map-marker {
  text-align: center;
  background-image: url("/assets/svgs/pin-chill.svg");
  p {
    font-family: $default-font;
    font-weight: 900;
    font-size: $font-size-big;
    line-height: $line-height-big;
    margin-top: calc(38px * 0.3);

    &.map-marker--more-than-two-digits {
      font-size: $font-size-regular;
    }
  }

  img {
    height: $font-size-big;
    margin-top: calc(38px * 0.5);
  }
}

.leaflet-popup-content-wrapper {
  padding: 0;
  border-radius: $radius-16;
}
.leaflet-popup-content {
  margin: 0;
  font-family: $default-font;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
}
