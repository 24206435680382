.footer {
  padding: 8 * $vertical-rhythm $gutterwidth 9 * $vertical-rhythm;
  background-color: $footer-white;
  box-shadow: 0 50vh 0 50vh $footer-white;
  // The second selector is for the storybook appearence
  .dark-background &,
  &.dark-background {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 50vh 0 50vh rgba(0, 0, 0, 0.1);
  }

  &.footer--in-menu {
    background: none;

    & .footer__inner {
      display: block;

      @include media-desktop {
        display: grid;
        grid-template-columns: auto 1fr;
      }

      .footer__menu-button,
      .footer__up-button {
        display: none;
      }
    }
  }
}

.footer__inner {
  display: grid;
  margin: 0 auto;
  max-width: $max-width;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(3 fr);
  grid-template-areas:
    "menu-up menu-up"
    "navigation navigation"
    "signature signature";

  @include media-desktop {
    grid-template-columns: auto 480px 110px;
    grid-template-rows: 100%;
    grid-template-areas: "navigation signature menu-up";
    align-items: start;
  }

  .footer__menu-button {
    grid-area: menu-button;
    justify-self: start;
  }

  .footer__up-button {
    grid-area: menu-up;
    justify-self: start;

    @include media-desktop {
        justify-self: end;
    }
  }

  .footer__navigation {
    grid-area: navigation;

    .footer__navigation__primary,
    .footer__navigation__secondary {
      & > li {
        padding: 0;

        &:before {
          content: none;
        }

        & > a {
          color: inherit;
          text-decoration: none;
          opacity: 1;
        }
      }

      @include media-desktop {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .footer__navigation__primary {
      margin-top: calc($vertical-rhythm * 5);

      @include media-desktop {
        margin-top: calc($vertical-rhythm / 2);

        & > li {
          &:not(:last-child) {
            margin-right: 2.8%;
          }
        }
      }
    }

    .footer__navigation__secondary {
      margin-top: calc($vertical-rhythm * 4);
      font-size: $font-size-small;
      line-height: $line-height-small;
      color: $grey;

      .dark-background & {
        color: rgba(255, 255, 255, 0.45);
      }

      @include media-desktop {
        margin-top: calc($vertical-rhythm / 2);

        & > li {
          &:not(:last-child) {
            margin-right: 2.8%;
          }
        }
      }
    }
  }

  .footer__signature {
    grid-area: signature;
    font-size: $font-size-small;
    line-height: $line-height-small;
    color: $grey;

    .dark-background & {
      color: rgba(255, 255, 255, 0.45);
    }

    @include media-desktop {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-top: 0;
    }

    .footer__signature__logo {
      width: 121px;
      height: 65px;

      margin-top: 16px;
      margin-bottom: 16px;

      @include media-desktop {
        margin-left: 36px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
