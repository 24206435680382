.portrait-container {
    max-width: $max-width;
    margin: 0 auto;

    @include media-desktop() {
        padding: $container-padding;
    }
    @include media-tablet() {
        padding: 20px;
    }
    @include media-mobile() {
        padding: 5px;
    }
  
    @mixin column() {
      display: grid;
      column-gap: $gutterwidth;
      row-gap: $gutterwidth;
    }
    @mixin col() {
      display: grid;
      column-gap: $gutterwidth * 2;
      row-gap: $gutterwidth;
    }

    .cols-1-1 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 1fr 1fr;
       }
    }
  
    .cols-2-1 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 2fr 1fr;
       }
    }
    .cols-1-2 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 1fr 2fr;
       }
    }
  
    .cols-1-5 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 1fr 5fr;
       }
    }
    .cols-5-1 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 5fr 1fr;
       }
    }
    .cols-1-3 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 1fr 3fr;
       }
    }
    .cols-3-1 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 3fr 1fr;
       }
    }
    .cols-4-8 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 4fr 8fr ;
       }
    }
    .cols-8-4 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 8fr 4fr;
       }
    }
    .cols-5-7 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 5fr 7fr ;
       }
    }
    .cols-7-5 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 7fr 5fr;
       }
    }
  
    .cols-1-7 {
      @include col();
       @include media-desktop() {
        grid-template-columns: 1fr 7fr;
       }
    }
  
  }