*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  color: $black;
  font-family: $default-font;
}

body {
  font-size: $font-size-regular;
}

.dark-background {
  color: $white;
}

p, li, details {
  line-height: $line-height-regular;
}

p:not(:last-child),
details:not(:last-child),
.default-bottom-margin {
  margin-bottom: $line-height-regular;
}

.super-small {
  font-size: $font-size-super-small;
  line-height: $line-height-super-small;
}

p.super-small:not(:last-child) {
  margin-bottom: $line-height-super-small;
}

.small {
  font-size: $font-size-small;
  line-height: $line-height-small;
}

p.small:not(:last-child) {
  margin-bottom: $line-height-small;
}

.big {
  font-size: $font-size-big;
  line-height: $line-height-big;
}

p.big:not(:last-child) {
  margin-bottom: $line-height-big;
}

.jumbo {
  font-size: var(--font-size-jumbo);
  line-height: var(--line-height-jumbo);
}

h1,
h2,
h3,
h4,
h5,
h6,
.jumbo {
  font-weight: 900;
  margin-bottom: $line-height-regular;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child),
h5:not(:first-child),
h6:not(:first-child),
.jumbo:not(:first-child) {
  margin-top: $line-height-regular;
}

p.no-horizontal-margin,
h1.no-horizontal-margin,
h2.no-horizontal-margin,
h3.no-horizontal-margin,
h4.no-horizontal-margin,
h5.no-horizontal-margin,
h6.no-horizontal-margin,
.jumbo.no-horizontal-margin {
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
}

h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
}

h3 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
}

h4 {
  font-size: $font-size-h4;
  line-height: $line-height-h4;
}

h5 {
  font-size: $font-size-h5;
  line-height: $line-height-h5;
}

h6 {
  font-size: $font-size-h6;
  line-height: $line-height-h6;
}

.font-style-normal {
  font-weight: normal;
}

.font-style-italics {
  font-style: italic;
}

.font-style-bold {
  font-weight: bold;
}

.font-color-light-grey {
  color: $light-grey;
}

.font-color-grey {
  color: $grey;
}

.font-color-chill {
  color: $chill;
}

.font-color-fun {
  color: $fun;
}

.font-transparent {
  opacity: 0.5;
}

.text-align-left {
  text-align-last: left;
}

.text-align-center {
  text-align-last: center;
}

.text-align-right {
  text-align-last: right;
}

mark {
  background-color: $happy;
  font-style: inherit;
  font-weight: inherit;
  padding: 3px 7px 2px 9px;

  .dark-background & {
    background-color: $chill;
  }
}

del {
  color: grey;

  .dark-background {
    color: $light;
  }
}

ins {
  background: none;
  color: $peace;
}

small {
  font-size: $font-size-small;
  line-height: $line-height-small;
  font-weight: 100;
}

ol {
  counter-reset: section;
  list-style: none;
  li {
    margin-left: 6px;
    &:before {
      counter-increment: section;
      content: counters(section, "");
      margin-right: 12px;

      color: $chill;

      .dark-background & {
        color: $happy;
      }
    }
  }
}

hr {
  border-color: $relax;

  .dark-background & {
    border-color: $happy;
  }

  &.easy {
    border-color: #e6ecf0;

    .dark-background & {
      border-color: #285677;
    }
  }

  &.brick {
    width: 29px;
    height: 6px;
    border: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc($vertical-rhythm * 8);
    margin-bottom: calc($vertical-rhythm * 8);

    background-color: $relax;

    .dark-background & {
      background-color: $happy;
    }
  }
}

%link {
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.3;
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.markdown-container {
  a {
    text-decoration: underline;
  }
}

blockquote {
  font-style: italic;
  font-weight: lighter;
  font-size: $font-size-h1;
  line-height: $line-height-h1;
  color: $sleep;
  text-align: center;

  .dark-background & {
    color: $happy;
  }
}

cite {
  font-style: italic;
  font-weight: lighter;
  $color: $grey;
  font-size: $font-size-regular;
  line-height: $line-height-regular;

  blockquote & {
    display: block;
    margin-top: calc($vertical-rhythm * 2);
  }

  .dark-background & {
    color: rgba(255, 255, 255, 0.5);
  }
}

code {
  font-family: Courier, monospace;
}

img {
  width: 100%;
}

details {
  summary {
    font-size: $font-size-big;
    line-height: $line-height-big;
    cursor: pointer;
  }
  summary::marker {
    color: $chill;
  }
  summary::-webkit-details-marker {
    color: $chill;
  }
}

svg {
  fill: $white;
  stroke: $white;
  stroke-width: 0px;;
}
