.species-gallery {

  margin-top: 64px;
  width: 100%;

  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

  img {
    border-radius: $radius-8;
    box-shadow: $shadow;
  }

  p.species-gallery__filter_info {
    font-size: $font-size-small;
  }

  .species-gallery__filter {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;

  
    .tags-filter {
      width: 30%;
      min-width: 250px;
    }

    .name-query-filter {
      width: 30%;
      min-width: 250px;
    }
  }

  

  .species-gallery__link {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
    font-weight: bold;
    margin: auto;
    width: fit-content;
    padding-top: $padding-32;
    
    img {
      border-radius: none;
      box-shadow: none;
      height: $font-size-h2;
      width: auto;
      vertical-align: bottom;
    }
    p {
      margin-bottom: 0px;
      a {
        border-bottom: 2px solid rgba(255, 255, 255, 0.5);
        white-space: nowrap;
      }
      img {
        height: $font-size-h3;
        margin-left: $padding-8;
      }
    }
    @include media-tablet() {
      font-size: $font-size-regular;
      line-height: normal;
      p img {
        height: $font-size-regular;
      }
    }
  }
}